import React, { Dispatch, SetStateAction } from "react";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { Icon } from "../../Layout/Icon/Icon";
import { preRoleplayDataTypes } from "../types";
import { useSettings } from "../../../context/Settings";
import { customPushToDataLayer } from "../../../analytics/analytics";
import { ControlButtonWithImage } from "./ControlButtonWithImage";

export interface DescriptionProps {
  data: preRoleplayDataTypes[];
  activeIndex: number;
  activeVideo: number;
  playPressed: boolean;
  setActiveIndex: Dispatch<SetStateAction<number>>
  setPlayPressed: Dispatch<SetStateAction<boolean>>;
  setActiveVideo: (value: React.SetStateAction<number>) => void;
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>;
  setConversationActive: Dispatch<SetStateAction<boolean>>;
}

export const Description: React.FunctionComponent<DescriptionProps> = ({
  data,
  activeIndex,
  activeVideo,
  playPressed,
  setActiveIndex,
  setActiveVideo,
  setPlayPressed,
  setIsPopupOpen,
  setConversationActive,
}) => {
  const { t, i18n } = useTranslation();
  const { settings } = useSettings();
  const language = settings.language;
  const buttonOverrideStyle = settings.isSafari ? { marginTop: "3px" } : {};

  const startConversation = () => {
    setConversationActive(true)
    customPushToDataLayer(
      {
        'event': 'roleplay_start',
        'roleplay_name': data[activeIndex].template_id,
        'roleplay_language' : i18n.language,
        'roleplay_action' : 'start'
    })
  };

  const previousName = activeIndex !== 0 ? data[activeIndex - 1].template_id : null;
  const nextName = activeIndex !== data.length - 1 ? data[activeIndex + 1].template_id : null;

  return (
    <div className="pre-roleplay__description">
      {data.map(
        ({ name, estimatedTime, description, story, trainingVidButtonText }, i) =>
          i === activeIndex && (
            <CSSTransition
              in
              classNames="fade"
              appear={true}
              timeout={800}
              key={i}
            >
              {playPressed ? (
                <button
                  className="close-button button"
                  onClick={() => {
                    setPlayPressed(false);
                    setActiveVideo(0);
                  }}
                  aria-label="Close video"
                >
                  <Icon name="close" stroke="#1E223C" fill="none" size={90} />
                </button>
              ) : (
                <>
                  <div className="description-content">
                    <div className="description-intro">
                      <h1 className="description-header">
                        {t("pre_roleplay.meet")} {name}
                      </h1>
                      <p className="description-text">
                        {t("pre_roleplay.estimated_time")}{" "}
                        <span className="description-text description-text--bold">
                          {estimatedTime}
                        </span>
                      </p>
                    </div>
                    <p className="description-text">{description}</p>
                    <button
                      className="button about-person__button about-person__button--desktop"
                      onClick={() => {
                        setPlayPressed(true);
                        setActiveVideo(activeVideo + 1);
                      }}
                    >
                      <span style={buttonOverrideStyle}>{story}</span>
                      <Icon name="play" size={28} fill="#1E223C" />
                    </button>
                  </div>
                  <CSSTransition
                    in
                    classNames="fade"
                    appear={true}
                    timeout={800}
                  >
                    <div className="description-control-buttons-wrapper">
                      {language === 'nl' && previousName ? 
                        <ControlButtonWithImage
                          onButtonClick={() => setActiveIndex(activeIndex - 1)}
                          name={previousName}
                          ariaLabel={t('icons.next')}
                        />
                      : null}
                      {language === 'nl' && nextName ? 
                        <ControlButtonWithImage
                          onButtonClick={() => setActiveIndex(activeIndex + 1)}
                          name={nextName}
                          ariaLabel={t('icons.next')}
                        />
                      : null}
                      <div className="description-buttons-wrapper">
                      <button
                        className="button description-button"
                        onClick={() => {
                          setPlayPressed(false);
                          setIsPopupOpen(true);
                        }}
                      >
                        <span style={buttonOverrideStyle}>
                          {trainingVidButtonText}
                        </span>
                      </button>
                      <button
                        className="button description-button description-button--blue"
                        onClick={startConversation}
                      >
                        <span style={buttonOverrideStyle}>
                          <Icon
                            name="union"
                            size={24}
                            height={24}
                            fill="#F9DDD2"
                          />
                          <span>{t("pre_roleplay.start_conversation")}</span>
                        </span>
                      </button>
                      </div>
                    </div>
                  </CSSTransition>
                </>
              )}
            </CSSTransition>
          )
      )}
    </div>
  );
};
