import { LanguageCodes } from "../../../i18n/i18n"

export const termsOfUseLink = (langCode: string): string => {
  switch (langCode) {
    case LanguageCodes.fi: return 'https://www.campus.sanofi/fi/kayttoehdot'
    case LanguageCodes.sv: return 'https://www.campus.sanofi/se/anvandarvillkor'
    case LanguageCodes.da: return 'https://www.campus.sanofi/dk/vilkar-betingelser'
    case LanguageCodes.nl: return 'https://www.sanofi.com/nl/nederland/terms-of-use'
    default: return '/'
  }
}

export const privacyPolicyLink = (langCode: string): string => {
  switch (langCode) {
    case LanguageCodes.fi: return 'https://www.sanofi.com/fi/suomi/data-privacy'
    case LanguageCodes.sv: return 'https://www.sanofi.com/sv/sverige/data-privacy'
    case LanguageCodes.da: return 'https://www.sanofi.com/da/danmark/data-privacy'
    case LanguageCodes.nl: return 'https://www.sanofi.com/nl/nederland/data-privacy'
    default: return '/'
  }
}

export const hcpPortalLink = (langCode: string): string => {
  switch (langCode) {
    case LanguageCodes.fi: return 'https://www.sanofijasina.fi/'
    case LanguageCodes.sv: return 'https://www.sanofipro.se/'
    case LanguageCodes.da: return 'https://www.sanofimed.dk/'
    default: return '/'
  }
}