import React from 'react';
import nlKirsten from '../../../assets/nlKirsten.png';
import nlThomas from '../../../assets/nlThomas.png';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../context/Settings';

export interface ControlButtonWithImageProps {
  onButtonClick: () => void;
  name: string;
  buttonClassName?: string;
  ariaLabel: string;
}

export const ControlButtonWithImage: React.FunctionComponent<ControlButtonWithImageProps> = ({
  onButtonClick,
  name,
  buttonClassName,
  ariaLabel,
}) => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const language = settings.language;

  const getImgSrc = () => {
    if (language === 'nl' && name === 'Kirsten') {
      return nlKirsten;
    } else if (language === 'nl' && name === 'Thomas') {
      return nlThomas;
    }
  };

  const imgSrc = getImgSrc();

  return (
    <div className="control-button-with-image-wrapper">
      <button
        className={`button control-button-with-image ${buttonClassName}`}
        onClick={onButtonClick}
        aria-label={ariaLabel}
      >
        <span className="control-button-with-image__text">
          {t('pre_roleplay.talk_to')} {name}
        </span>
        <img className="control-button-with-image__image" src={imgSrc} alt={name} />
      </button>
    </div>
  );
};
